import type { ItemPriceVariant, PriceVariant, PriceVariantInItem } from '../types/priceVariants';

export const getIdsToPriceVariantsMap = (allPriceVariants: PriceVariant[]) => {
  return allPriceVariants?.reduce((acc, priceVariant) => {
    if (priceVariant.id) {
      acc[priceVariant.id] = priceVariant;
    }
    return acc;
  }, {} as Record<string, PriceVariant>);
};

export const getPriceVariantsWithName = ({
  allPriceVariants,
  priceVariants,
}: {
  allPriceVariants: Record<string, PriceVariant>;
  priceVariants?: ItemPriceVariant[];
}) => {
  return (priceVariants || [])?.reduce((acc, priceVariant) => {
    const selectedPriceVariant = allPriceVariants[priceVariant.id];
    if (selectedPriceVariant) {
      acc = acc.concat({
        id: priceVariant.id,
        name: selectedPriceVariant?.name,
        price: priceVariant.price,
      });
    }
    return acc || [];
  }, [] as PriceVariantInItem[]);
};

export const getVariantById = ({
  priceVariantsInItem,
  variantId,
}: {
  priceVariantsInItem: PriceVariantInItem[];
  variantId: string;
}) => priceVariantsInItem.find((priceVariant) => priceVariant.id === variantId);

export const getVariantWithMinimalPrice = (
  variantListItems: PriceVariantInItem[]
): PriceVariantInItem =>
  variantListItems.reduce(
    (prev, curr) => (Number(prev.price) <= Number(curr.price) ? prev : curr),
    {} as PriceVariantInItem
  );

export const getPriceVariantsIds = (priceVariants?: PriceVariantInItem[]) => {
  return (
    priceVariants?.map((priceVariant) => {
      return priceVariant.id;
    }) ?? []
  );
};
