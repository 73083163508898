import type {
  ModifierGroup as ModifierGroupTPA,
  Modifier as ModifierAdditionalChargeTPA,
  Rule as RuleTPA,
} from '@wix/ambassador-restaurants-menus-v1-item-modifier-group/types';
import type { Modifier as ModifierTPA } from '@wix/ambassador-restaurants-menus-v1-item-modifier/types';
import type {
  PriceVariant as PriceVariantTPA,
  ModifierGroup as ItemModifierGroupTPA,
} from '@wix/ambassador-restaurants-menus-v1-item/types';
import type {
  PopulatedModifierGroup as PopulatedModifierGroupTPA,
  DisplayedModifier as PopulatedModifierTPA,
  ModifierGroupRule as PopulatedModifierGroupRuleTPA,
} from '@wix/restaurants-populated-item-client/types';

export type ModifierGroupTpa = ModifierGroupTPA;
export type ModifierTpa = ModifierTPA;
export type RuleTpa = RuleTPA;
export type PriceVariantTpa = PriceVariantTPA;
export type ModifierAdditionalChargeTpa = ModifierAdditionalChargeTPA;
export type ItemModifierGroupTpa = ItemModifierGroupTPA;
export type PopulatedModifierGroupTpa = PopulatedModifierGroupTPA<{ modifiers: true }>;
export type PopulatedModifierTpa = PopulatedModifierTPA;
export type PopulatedModifierGroupRuleTpa = PopulatedModifierGroupRuleTPA;

export type ModifierGroupRule = {
  minSelections?: number;
  maxSelections?: number | null;
  required?: boolean;
};

export type ModifierGroup = {
  id?: string;
  name?: string;
  modifiers?: ModifierAdditionalCharge[];
  rule?: ModifierGroupRule;
};

export type ItemModifierGroup = {
  id: string;
};

export type Modifier = {
  id?: string;
  name?: string;
};

export type ModifierAdditionalCharge = {
  id?: string;
  price?: string;
  preSelected?: boolean;
};

export type ModifierListItem = {
  id?: string;
  modifierId?: string;
  name?: string;
  price?: string;
  isPreSelected?: boolean;
};

export type PriceVariant = {
  id: string;
  name?: string;
  price?: string;
};

export type ModifierGroupListItem = {
  id: string;
  name?: string;
  rule?: ModifierGroupRule;
  modifiers?: ModifierListItem[];
};

export type RepeaterModifierGroupListItem = ModifierGroupListItem & { modifierGroupId: string };
export type ModifierRepeaterData = ModifierGroupListItem & { _id: number };

export enum RuleType {
  NO_LIMIT,
  CHOOSE_ONE,
  CHOOSE_X,
  CHOOSE_AT_LEAST_ONE,
  CHOOSE_AT_LEAST_X,
  CHOOSE_UP_TO_X,
  CHOOSE_BETWEEN_X_AND_Y,
}

export type SelectedModifiers = Record<string, ModifierListItem[]>;
