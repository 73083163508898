import type { CatalogReferenceModifierGroup } from 'root/services/cartService';
import type {
  ModifierGroupListItem,
  ModifierListItem,
  RepeaterModifierGroupListItem,
  SelectedModifiers,
} from 'root/types/modifiers';
import { v4 as uuidv4 } from 'uuid';

export const getModifierGroupsOptions = (
  modifierGroups: RepeaterModifierGroupListItem[],
  selectedModifiers: SelectedModifiers,
  formatCurrency: (price?: number) => string
) => {
  const modifierGroupsOptions = Object.keys(selectedModifiers)
    ?.map((modifierGroupId) => {
      const selectedModifierGroup = modifierGroups.find(
        (modifierGroup) => modifierGroup.id === modifierGroupId
      );
      return {
        id: selectedModifierGroup?.modifierGroupId ?? '',
        modifiers: selectedModifiers[modifierGroupId]?.map((modifier) => {
          const price = Number(modifier?.price);
          return {
            id: modifier.modifierId,
            price: modifier?.price,
            formattedPrice: price > 0 ? formatCurrency(price) : undefined,
          };
        }),
      };
    })
    .filter((modifierGroup) => Boolean(modifierGroup.modifiers.length && modifierGroup.id !== ''));
  return modifierGroupsOptions.length > 0
    ? (modifierGroupsOptions as CatalogReferenceModifierGroup[])
    : undefined;
};

export const getModifierListItem = (modifiers: ModifierListItem[], value: string) => {
  return modifiers.find((modifier) => value === modifier.id);
};

export const getFormattedModifierLabel = (
  name: string,
  price: string,
  isAdditionalCharge?: boolean
) => {
  return isAdditionalCharge ? `${name} (+${price})` : name;
};

export const getModifiersOptions = (
  modifiers: ModifierListItem[],
  formatCurrency: (price?: number) => string
) => {
  return modifiers.map((modifier) => {
    const isAdditionalCharge = Number(modifier.price) > 0;
    const formattedPrice = formatCurrency(Number(modifier.price));
    const label = getFormattedModifierLabel(
      modifier.name ?? '',
      formattedPrice,
      isAdditionalCharge
    );
    return {
      label,
      value: modifier.id,
    };
  });
};

const convertToRepeaterModifiers = (modifiers?: ModifierListItem[]) => {
  return modifiers?.map((modifier, index) => ({
    ...modifier,
    id: `${modifier.id}-${index}`,
    modifierId: modifier.id,
  }));
};

export const convertToRepeaterModifierGroups = (
  entities: ModifierGroupListItem[]
): RepeaterModifierGroupListItem[] => {
  return entities.map((entity) => ({
    ...entity,
    id: uuidv4(),
    modifierGroupId: entity.id,
    modifiers: convertToRepeaterModifiers(entity.modifiers),
  }));
};
