import type { DispatchState } from '../../types/businessTypes';
import { PersistStorageKeys, PersistStorageService } from '../persistStorageService';
import type { SerializedDispatchState } from '../serialization/DispatchStateSerializer';
import { DispatchStateSerializer } from '../serialization/DispatchStateSerializer';

export interface IPersistDataService {
  getDispatchState: () => DispatchState | undefined;
  setDispatchState: (state: DispatchState) => void;
}

export const PersistDataService = (storage: {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}): IPersistDataService => {
  const persistStorageService = PersistStorageService(storage);
  const returnObject = {
    getDispatchState: (): DispatchState | undefined => {
      try {
        const serialized = persistStorageService.getItem<SerializedDispatchState>(
          PersistStorageKeys.dispatchState
        );
        if (!serialized) {
          return;
        }
        return DispatchStateSerializer.deserialize(serialized);
      } catch (e) {
        console.error('getDispatchState error :>> ', e);
      }
    },
    setDispatchState: (state: DispatchState) => {
      persistStorageService.removeItem(PersistStorageKeys.dispatchState);
      persistStorageService.setItem<SerializedDispatchState>(
        PersistStorageKeys.dispatchState,
        DispatchStateSerializer.serialize(state)
      );
    },
  };
  return returnObject;
};
