import model from './model';
import { DishesController } from './dishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { hasDataChanged } from '../../utils/utils';
import type { DishesWidgetProps } from '../../types/menusTypes';
import type { Item } from 'root/types/menusTypes';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const { controllerConfig, translations, environment, formatCurrency, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const { isMobile, isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;

  const dishesController = new DishesController(
    $bind,
    $w,
    t,
    formatCurrency,
    isMobile,
    experiments,
    isMemberLoggedIn
  );

  let prevItems: Item[] = [];

  $widget.onPropsChanged((_, nextProps) => {
    const isMenuAvailabilityChanged = nextProps.data.isMenuAvailable !== _.data.isMenuAvailable;
    const shouldInit =
      isMenuAvailabilityChanged ||
      hasDataChanged({
        prevData: prevItems,
        nextData: nextProps.data?.items,
      });

    if (shouldInit) {
      const { items, menuId, sectionId, isMenuAvailable }: DishesWidgetProps = nextProps.data;
      prevItems = items;
      dishesController.init(items, isEditor, menuId, sectionId, isMenuAvailable);
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { items, menuId, sectionId, isMenuAvailable }: DishesWidgetProps = $widget.props.data;
      if (isEditor && items.length > 0) {
        dishesController.deleteItemCounterInEditor(menuId);
      }
      const shouldInit = hasDataChanged({
        prevData: prevItems,
        nextData: items,
      });
      if (shouldInit) {
        prevItems = items;
        dishesController.init(items, isEditor, menuId, sectionId, isMenuAvailable);
      }
    },
    exports: {},
  };
});
