import type { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import { LabelsClient } from './labelsClient';
import { ItemsClient } from './itemsClient';
import { MenusClient } from './menusClient';
import { SectionsClient } from './sectionsClient';
import type { PopulatedMenu } from '../types/menusTypes';
import { convertDataTypes, populateMenu } from './utils/utils';
import { state } from 'root/states/RootState';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { SPECS } from 'root/appConsts/experiments';

export const PopulatedMenuClient = ({
  httpClient,
  experiments,
  msid = '',
  currency,
  sentry,
}: {
  httpClient: IHttpClient;
  experiments: Experiments;
  msid: string | undefined;
  currency: string;
  sentry?: ErrorMonitor;
}) => {
  const getMenusByOperation = experiments.enabled(SPECS.getMenusByOperation);

  const menusClient = new MenusClient(httpClient);
  const sectionsClient = new SectionsClient(httpClient);
  const itemsClient = new ItemsClient(httpClient);
  const labelsClient = new LabelsClient(httpClient);

  return {
    getAll: async (menuIdsPromise?: Promise<string[] | undefined>) => {
      try {
        const menuIds = await menuIdsPromise;

        if (getMenusByOperation && !menuIds?.length) {
          sentry?.addBreadcrumb({
            category: 'get menus',
            message: 'menuIds is empty for selected operation',
          });
          sentry?.captureException(new Error(`menuIds is empty for ${msid}`));
          return;
        }

        const [menuResponse, sectionResponse, itemResponse, labelResponse] = await Promise.all([
          menusClient.fetchListVisibleMenus(menuIds),
          sectionsClient.fetchAllSections(),
          itemsClient.fetchAllItems(),
          labelsClient.fetchAllLabels(),
        ]);
        const itemsWithoutImgSize =
          itemResponse.items?.filter(
            (item) => item.image && item.image.id && !(item.image.width || item.image.height)
          ) || [];

        const fileIds = itemsWithoutImgSize.map((item) => item.image!.id!);
        let imagesData;
        if (fileIds.length > 0) {
          state.biReporterService?.reportOloGenericDebugBiEvent({
            subjectType: 'fix width and height of images',
            value: {
              fileIds: JSON.stringify(fileIds),
              fileIdsLength: fileIds.length,
              msid,
            },
          });
          imagesData = await itemsClient.getImgsData(fileIds, msid);
        }

        const { menus, sections, items } = await convertDataTypes({
          currency,
          menusTpa: menuResponse.menus,
          sectionsTpa: sectionResponse.sections,
          itemsTpa: itemResponse.items,
          labelsTpa: labelResponse.labels,
          imagesData,
        });

        return menus.map((menu) => populateMenu(menu, sections, items, sentry)) as PopulatedMenu[];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('fetch data failed', e);
        state.pubsub.publish('onMenusDataFetchFailed', { shouldShowErrorContent: true });
      }
    },
  };
};
