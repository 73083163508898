import model from './model';
import type { Operation } from '../../types/businessTypes';
import type { HeaderWidgetProps } from '../../types/menusTypes';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { HeaderController } from './headerController';

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const {
    formatCurrency,
    translations,
    controllerConfig,
    environment,
    experiments,
    formatAddress,
  } = flowAPI;
  const t = translations.t as TFunction;
  const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser.loggedIn;

  const headerController = new HeaderController(
    $bindAll,
    $w,
    t,
    formatCurrency,
    formatAddress,
    controllerConfig.wixCodeApi.site,
    isMemberLoggedIn,
    environment.isSSR,
    experiments
  );
  const initHeaderController = async (props: { operation: Operation; data: HeaderWidgetProps }) => {
    if (shouldInitHeaderTexts(props)) {
      headerController.initHeaderTexts({ ...props.data, t });
    }

    const operation = props.operation;

    if (operation?.id) {
      headerController.init({ operation });
    }
  };

  const shouldInitHeaderTexts = (props: { data: HeaderWidgetProps }) => {
    const { headerTitle, headerDescription }: HeaderWidgetProps = props.data;
    return headerTitle || headerDescription;
  };
  const shouldInitHeader = (
    props: { operation: Operation; data: HeaderWidgetProps },
    newProps: { operation: Operation; data: HeaderWidgetProps }
  ) => !props.operation && newProps.operation;

  $widget.onPropsChanged((_, nextProps) => {
    if (shouldInitHeader(_, nextProps)) {
      initHeaderController(nextProps);
    }
    if (shouldInitHeaderTexts(nextProps)) {
      headerController.initHeaderTexts({ ...nextProps.data, t });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      headerController.deleteFulfillmentContainer();
      headerController.deleteBadgesContainer();
      headerController.initHeaderTexts({ ...$widget.props.data, t });
      await initHeaderController($widget.props);
    },
    exports: {},
  };
});
