import type { ModifierListItem, ItemModifierGroup, ModifierGroup } from '../types/modifiers';

export const getModifiersIds = (modifierGroups?: ModifierGroup[]): string[] => {
  const modifierIdsByModifierGroups = modifierGroups?.map(
    (modifierGroup) => modifierGroup.modifiers
  );
  const modifiersObjects = modifierIdsByModifierGroups?.flat(1);
  return (modifiersObjects?.map((modifier) => modifier?.id) ?? []) as string[];
};

export const getModifierGroupsIds = (modifierGroups?: ItemModifierGroup[]) => {
  return (
    modifierGroups?.map((modifierGroup) => {
      return modifierGroup.id;
    }) ?? []
  );
};

export const sumAdditionalCharges = (selectedModifiers: Record<string, ModifierListItem[]>) => {
  const modifiers = Object.values(selectedModifiers).flat(1);
  const additionalChargesPerModifier = modifiers.map((modifier) => Number(modifier.price) ?? 0);
  return additionalChargesPerModifier.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
};
