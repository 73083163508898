import type { IHttpClient } from '@wix/yoshi-flow-editor';

import { PopulatedModifierGroupClientFactory } from '@wix/restaurants-populated-item-client/web';

export class PopulatedItemModifierGroupsClient {
  httpClient;
  populatedModifiersClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.populatedModifiersClient = PopulatedModifierGroupClientFactory({
      httpClient,
      populationLevel: { modifiers: true },
    });
  }

  getById = async (modifierGroupId: string) => {
    try {
      return this.populatedModifiersClient.getById(modifierGroupId).then((response) => response);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getAll = async (modifierGroupIds?: string[]) => {
    try {
      return this.populatedModifiersClient.getBulk({ ids: modifierGroupIds }).then((response) => {
        if (response.hasError) {
          throw response.error;
        }
        return response?.data;
      });
    } catch (e: unknown) {
      console.error(e);
      return;
    }
  };
}
