import type { Experiments, I$W, ISiteApis, TFunction } from '@wix/yoshi-flow-editor';
import { HEADER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { FulfillmentContextProps } from '../../contexts/FulfillmentContext';
import { state as rootState } from '../../states/RootState';
import { DEFAULT_LOCALE, ANOTHER_TIMEZONE } from '../../api/consts';
import type { BindAll, IHeaderController } from './types';
import { HeaderStore } from 'root/states/HeaderStore';
import {
  getCartShippingDetailsFromHeaderStore,
  STATUS_INDICATOR_COLORS,
  resolveTimeString,
  getOnDispatchTypeChange,
  openDispatchModal,
} from './headerUtils';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import type { Address } from 'root/types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { dispatchState } from 'root/states/DispatchState';
import { SPECS } from 'root/appConsts/experiments';

export class HeaderController implements IHeaderController {
  private headerStore!: HeaderStore;

  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private t: TFunction,
    private formatCurrency: Function,
    private addressFormatter: Function,
    private site: ISiteApis,
    private isMemberLoggedIn: boolean | undefined,
    private isSSR: boolean,
    private experiments: Experiments
  ) {}

  init({ operation }: FulfillmentContextProps) {
    const timezone = this.site.timezone || ANOTHER_TIMEZONE;
    const locale = this.site.regionalSettings || DEFAULT_LOCALE;
    this.headerStore = new HeaderStore(operation, timezone, locale);

    // eslint-disable-next-line no-console
    console.log('headerController isSSR :>> ', this.isSSR);
    if (!this.isSSR && this.headerStore.isOpen) {
      const shippingDetails = getCartShippingDetailsFromHeaderStore(this.headerStore);
      rootState.CartService?.setShippingDetails(shippingDetails);
    }

    rootState.initialTimeSlotText = resolveTimeString({
      asapOptions: {
        isASAP: this.headerStore.isASAP,
        exactTime: this.headerStore.asapTimeExact,
        timeRange: this.headerStore.asapTimeRange,
      },
      selectedTime: this.headerStore.selectedTime,
      t: this.t,
      timezone,
      dispatchType: this.headerStore.dispatchType,
    });

    if (this.headerStore.hasDispatchDetails) {
      this.restoreFulfillmentContainer();
    }

    this.restoreBadgesContainer();

    const isNewPriceFormattingEnabled = this.experiments.enabled(
      SPECS.localeDatasetCurrencyFormatting
    );

    const isAddressFormatterEnabled = this.experiments.enabled(SPECS.useAddressFormatter);

    const formatPrice = (price?: string) => {
      let formattedMin = '';
      if (typeof price !== 'undefined') {
        formattedMin = isNewPriceFormattingEnabled
          ? rootState.priceFormatter(Number(price))
          : this.formatCurrency({
              value: price,
              currency: rootState.currency,
            });
      }
      return formattedMin;
    };

    const formatAddress = (address?: Address) => {
      if (address && isAddressFormatterEnabled && this.addressFormatter) {
        return this.addressFormatter({ address }, { appendCountry: false })
          .filter((part: string) => part && /\S/.test(part))
          .join(', ');
      }
      return (
        (address?.formattedAddress ||
          address?.addressLine ||
          address?.streetAddress?.formattedAddressLine) ??
        ''
      );
    };

    this.$bindAll({
      [HEADER_WIDGET_COMPONENT_IDS.headerContainer]: {
        onViewportEnter: () => rootState.pubsub.publish('onHeaderViewportEnter'),
        onViewportLeave: () => rootState.pubsub.publish('onHeaderViewportLeave'),
      },
      [HEADER_WIDGET_COMPONENT_IDS.fulfillmentPicker]: {
        data: () => ({
          onDispatchTypeChange: getOnDispatchTypeChange(this.headerStore, this.isMemberLoggedIn),
          selectedDispatchType: this.headerStore.dispatchType,
          operation,
          availableDispatchTypes: dispatchState.availableDispatchTypes,
          configuredDispatchTypes: dispatchState.configuredDispatchTypes,
          hasPopup: true,
        }),
        collapsed: () => !this.headerStore.hasConfiguredDispatches,
      },
      [HEADER_WIDGET_COMPONENT_IDS.acceptOrders]: {
        text: () =>
          this.headerStore.hasAvailableDispatches
            ? this.t('header_olo.status.AcceptingOrders')
            : this.t('header_olo.status.NotAcceptingOrders'),
      },
      [HEADER_WIDGET_COMPONENT_IDS.statusIndicator]: {
        style: this.headerStore.hasAvailableDispatches
          ? { backgroundColor: () => STATUS_INDICATOR_COLORS.ONLINE }
          : { backgroundColor: () => STATUS_INDICATOR_COLORS.OFFLINE },
      },
      [HEADER_WIDGET_COMPONENT_IDS.minOrder]: {
        collapsed: () => !this.headerStore.minOrder || this.headerStore.minOrder === '0',
        text: () => {
          return this.t('header_olo.minOrder.exact', {
            amount: formatPrice(this.headerStore.minOrder),
          });
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.minOrderWrapper]: {
        collapsed: () => !this.headerStore.minOrder || this.headerStore.minOrder === '0',
      },
      [HEADER_WIDGET_COMPONENT_IDS.deliveryFee]: {
        collapsed: () => !this.headerStore.deliveryFee,
        text: () => {
          return this.headerStore.deliveryFee === '0'
            ? this.t('header_olo.deliveryFee.free')
            : this.t('header_olo.deliveryFee.exact', {
                amount: formatPrice(this.headerStore.deliveryFee),
              });
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.deliveryFeeWrapper]: {
        collapsed: () => !this.headerStore.deliveryFee,
      },
      [HEADER_WIDGET_COMPONENT_IDS.freeDelivery]: {
        collapsed: () =>
          !this.headerStore.freeDispatchPriceThreshold || this.headerStore.deliveryFee === '0',
        text: () => {
          return this.headerStore.freeDispatchPriceThreshold === '0'
            ? this.t('header_olo.deliveryFee.free')
            : this.t('header_olo.deliveryFee.freeAbove', {
                amount: formatPrice(this.headerStore.freeDispatchPriceThreshold),
              });
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.freeDeliveryWrapper]: {
        collapsed: () => !this.headerStore.freeDispatchPriceThreshold,
      },
      [HEADER_WIDGET_COMPONENT_IDS.addressInfo]: {
        text: () => {
          const prefix = this.headerStore.isDelivery
            ? this.t('header_olo.address.delivery')
            : this.t('header_olo.address.pickup');
          const { selectedAddress } = this.headerStore;
          return prefix + formatAddress(selectedAddress);
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.addressInfoLine]: {
        collapsed: () =>
          !this.headerStore.hasConfiguredDispatches ||
          (this.headerStore.onlyDeliveryConfigured && !this.headerStore.hasAvailableDispatches),
      },
      [HEADER_WIDGET_COMPONENT_IDS.timeInfo]: {
        text: () => {
          const text = resolveTimeString({
            asapOptions: {
              isASAP: this.headerStore.startsNow,
              exactTime: this.headerStore.asapTimeExact,
              timeRange: this.headerStore.asapTimeRange,
            },
            selectedTime: this.headerStore.selectedTime,
            t: this.t,
            timezone,
            dispatchType: this.headerStore.dispatchType,
          });
          return text;
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.timeInfoLine]: {
        collapsed: () => !this.headerStore.hasAvailableDispatches,
      },
      [HEADER_WIDGET_COMPONENT_IDS.addressChange]: {
        onClick: () => {
          rootState.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.CHANGE_ADDRESS,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          openDispatchModal(this.headerStore, this.headerStore.dispatchState);
        },
        label: () => {
          return this.headerStore.selectedAddress
            ? this.t('header_olo.address.delivery.change')
            : this.t('header_olo.address.delivery.add');
        },
        accessibility: {
          ariaAttributes: {
            describedBy: () => this.$w(HEADER_WIDGET_COMPONENT_IDS.addressInfo),
          },
        },
        collapsed: () => this.headerStore.dispatchType !== DispatchType.DELIVERY,
      },
      [HEADER_WIDGET_COMPONENT_IDS.timeChange]: {
        onClick: () => {
          rootState.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
            origin: LiveSiteClickFulfillmentOrigin.CHANGE_TIME,
            isMemberLoggedIn: this.isMemberLoggedIn,
          });
          openDispatchModal(this.headerStore, this.headerStore.dispatchState);
        },
        label: () => this.t('header_olo.time.change'),
        collapsed: () => this.headerStore.isASAP,
        accessibility: {
          ariaAttributes: {
            describedBy: () => this.$w(HEADER_WIDGET_COMPONENT_IDS.timeInfo),
          },
        },
      },
    });
  }

  initHeaderTexts({
    t,
    headerTitle,
    headerDescription,
  }: {
    t: TFunction;
    headerTitle: string;
    headerDescription: string;
  }) {
    this.$bindAll({
      [HEADER_WIDGET_COMPONENT_IDS.headerTitle]: {
        text: () => {
          return headerTitle || t('header_olo.default.title');
        },
      },
      [HEADER_WIDGET_COMPONENT_IDS.headerDescription]: {
        text: () => headerDescription || t('header_olo.default.description'),
      },
    });
  }

  deleteElement(selector: string) {
    this.$w(selector).delete();
  }

  restoreElement(selector: string) {
    this.$w(selector).restore();
  }

  deleteFulfillmentContainer() {
    this.deleteElement(HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer);
  }

  restoreFulfillmentContainer() {
    this.restoreElement(HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer);
  }

  deleteBadgesContainer() {
    this.deleteElement(HEADER_WIDGET_COMPONENT_IDS.badgesContainer);
  }

  restoreBadgesContainer() {
    this.restoreElement(HEADER_WIDGET_COMPONENT_IDS.badgesContainer);
  }
}
