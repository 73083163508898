import type { TFunction } from '@wix/yoshi-flow-editor';
import { ItemModalController } from './itemModalController';
import model from './model';
import { getModifierGroupsIds } from '../../utils/modifiersUtils';
import {
  getIdsToPriceVariantsMap,
  getPriceVariantsIds,
  getPriceVariantsWithName,
} from '../../utils/priceVariantsUtils';
import { doesArrayDataExist } from '../../utils/utils';
import { PopulatedModifiersClient, type ModifiersState } from '../../api/PopulatedModifiersClient';
import { registerToUrlChange } from 'root/utils/navigationUtils';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import type { Item, PopulatedMenu } from 'root/types/menusTypes';
import type { ItemPriceVariant } from 'root/types/priceVariants';
import type { ItemModifierGroup } from 'root/types/modifiers';
import {
  PopulatedPriceVariantsClient,
  type PriceVariantsState,
} from 'root/api/PopulatedPriceVariantsClient';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { DEFAULT_LOCALE } from 'root/api/consts';
import { PriceFormattingConverter } from '@wix/restaurants-olo-operations-client-commons';
import { getSiteCurrency } from 'root/utils/currency';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { PopulateMenuIdsByOperationClient } from 'root/api/PopulateMenuIdsByOperationClient';
import { SPECS } from 'root/appConsts/experiments';
import { convertToRepeaterModifierGroups } from 'root/utils/itemModalControllerUtils';

export default model.createController(({ $w, $bindAll, $bind, $widget, flowAPI }) => {
  const {
    formatCurrency,
    controllerConfig,
    translations,
    experiments,
    reportError,
    environment,
    httpClient,
    errorMonitor,
  } = flowAPI;

  const window = controllerConfig.wixCodeApi.window;
  const { isEditor, isPreview } = environment;
  const lightboxContext = window.lightbox.getContext() || {};
  const { data = {}, onModalOpen, closeModal } = lightboxContext;
  const t = translations.t as TFunction;
  const locale = controllerConfig.wixCodeApi.site.regionalSettings || DEFAULT_LOCALE;
  const priceFormatter = PriceFormattingConverter.createPriceFormatter(
    locale,
    getSiteCurrency(flowAPI)
  );

  const itemModalController = new ItemModalController(
    $bindAll,
    $bind,
    $w,
    t,
    formatCurrency,
    priceFormatter,
    window,
    isEditor,
    experiments
  );

  const getFirstItem = (menus: PopulatedMenu[]): Item | undefined => {
    let firstItem;
    const menuWithItems = menus.find((menu) =>
      menu.sections.some((section) => section.items?.length)
    );
    if (menuWithItems) {
      const sectionWithItems = menuWithItems.sections?.find((section) => section.items?.length);
      firstItem = sectionWithItems?.items?.[0];
    }

    return firstItem;
  };

  const getItem = async () => {
    const { item, canAcceptOrders, fedopsLogger } = data ?? {};
    if ((isEditor || isPreview) && !item) {
      try {
        const { metaSiteId = '' } = flowAPI.controllerConfig.platformAPIs.bi || {};
        const getMenusByOperation =
          experiments.enabled(SPECS.multiPages) && experiments.enabled(SPECS.getMenusByOperation);
        const pageOperationIdPromise = getMenusByOperation
          ? getPageOperationId(flowAPI.controllerConfig.wixCodeApi.site, flowAPI.errorMonitor)
          : undefined;

        const menuIdsByOperation = PopulateMenuIdsByOperationClient({
          httpClient,
          experiments,
          sentry: errorMonitor,
          fedopsLogger,
        }).getAll(pageOperationIdPromise);

        const menus = await PopulatedMenuClient({
          httpClient,
          experiments,
          msid: metaSiteId,
          currency: getSiteCurrency(flowAPI),
        }).getAll(menuIdsByOperation);
        return { item: menus?.length ? getFirstItem(menus) : undefined, canAcceptOrders: true };
      } catch (err) {
        reportError(new Error(`Editor mode - fetch data for item modal error ${err}`));
      }
    }
    return { item, canAcceptOrders };
  };

  const processModifiers = ({
    modifiersState,
    priceVariantsState,
    itemPriceVariants,
    priceVariantsIds,
  }: {
    modifiersState: ModifiersState;
    priceVariantsState: PriceVariantsState;
    itemPriceVariants: ItemPriceVariant[];
    itemModifierGroups: ItemModifierGroup[];
    priceVariantsIds: string[];
    modifierGroupsIds: string[];
  }) => {
    const statePriceVariants = priceVariantsState.priceVariants;
    const idsToPriceVariantsMap = getIdsToPriceVariantsMap(statePriceVariants);

    const priceVariants = priceVariantsIds
      ? getPriceVariantsWithName({
          allPriceVariants: idsToPriceVariantsMap,
          priceVariants: itemPriceVariants,
        })
      : [];
    const modifierGroups = convertToRepeaterModifierGroups(modifiersState.modifierGroups ?? []);

    return { priceVariants, modifierGroups };
  };

  const initModifiers = async ({
    priceVariantsIds,
    modifierGroupsIds,
    shouldFetchPriceVariantsData,
    shouldFetchModifiersData,
    fedopsLogger,
  }: {
    priceVariantsIds: string[];
    modifierGroupsIds: string[];
    shouldFetchPriceVariantsData: boolean;
    shouldFetchModifiersData: boolean;
    fedopsLogger?: FedopsLogger;
  }) => {
    let modifiersPromise = Promise.resolve({} as ModifiersState);
    let variantsPromise = Promise.resolve({} as PriceVariantsState);
    if (shouldFetchModifiersData) {
      modifiersPromise = PopulatedModifiersClient(flowAPI, fedopsLogger).getAll({
        modifierGroupsIds,
      });
    }
    if (shouldFetchPriceVariantsData) {
      variantsPromise = PopulatedPriceVariantsClient(flowAPI, fedopsLogger).getAll({
        priceVariantsIds,
      });
    }

    return Promise.all([modifiersPromise, variantsPromise]);
  };

  const processedItemDataPromise = getItem().then(async ({ item, canAcceptOrders }) => {
    const priceVariantsIds = getPriceVariantsIds(item?.priceVariants);
    const modifierGroupsIds = getModifierGroupsIds(item?.modifierGroups);
    return initModifiers({
      priceVariantsIds,
      modifierGroupsIds,
      shouldFetchPriceVariantsData: doesArrayDataExist(getPriceVariantsIds(item?.priceVariants)),
      shouldFetchModifiersData: doesArrayDataExist(getModifierGroupsIds(item?.modifierGroups)),
      fedopsLogger: data.fedopsLogger,
    }).then(([modifiersState, priceVariantsState]) => {
      const { priceVariants, modifierGroups } = processModifiers({
        modifiersState,
        priceVariantsState,
        itemPriceVariants: item?.priceVariants ?? [],
        itemModifierGroups: item?.modifierGroups ?? [],
        priceVariantsIds,
        modifierGroupsIds,
      });

      return { item, canAcceptOrders, priceVariants, modifierGroups };
    });
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      registerToUrlChange(controllerConfig.wixCodeApi, closeModal, window);
      try {
        const { item, canAcceptOrders, priceVariants, modifierGroups } =
          await processedItemDataPromise;

        itemModalController.init(
          { ...data, item, canAcceptOrders },
          onModalOpen,
          closeModal,
          priceVariants,
          modifierGroups
        );
      } catch (e) {
        console.error(new Error(`fetch data failed ${e} ${isEditor} ${isPreview}`));
      }
    },
    exports: {},
  };
});
