import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { convertPopulatedModifierGroupToModifierGroupType } from '../utils/modifiersConvertUtils';
import { PopulatedItemModifierGroupsClient } from './modifierGroupsClient';
import { doesArrayDataExist } from 'root/utils/utils';
import type { PopulatedModifierGroupTpa, ModifierGroupListItem } from 'root/types/modifiers';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';

export type ModifiersState = {
  modifierGroups: ModifierGroupListItem[];
};

export const PopulatedModifiersClient = (
  flowAPI: PlatformControllerFlowAPI,
  fedops?: FedopsLogger
) => {
  const modifiersState: ModifiersState = {
    modifierGroups: [],
  };

  const modifierGroupsClient = new PopulatedItemModifierGroupsClient(flowAPI.httpClient);

  return {
    getAll: async ({ modifierGroupsIds }: { modifierGroupsIds?: string[] }) => {
      let modifierGroupsResponse;

      if (doesArrayDataExist(modifierGroupsIds)) {
        modifierGroupsResponse = await getMonitoredApiCall({
          callback: () => modifierGroupsClient.getAll(modifierGroupsIds),
          fedops: {
            start: fedops?.fetchAllModifierGroupsStarted,
            end: fedops?.fetchAllModifierGroupsEnded,
          },
          reportError: flowAPI.reportError,
        });
      }
      modifiersState.modifierGroups = convertPopulatedModifierGroupToModifierGroupType(
        (modifierGroupsResponse?.data?.data || []) as PopulatedModifierGroupTpa[]
      );
      return modifiersState;
    },
  };
};
