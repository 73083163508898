import type { Bind } from '@wix/velocycle-mobx';
import type { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { CART_BUTTON_COMPONENT_IDS } from 'root/appConsts/blocksIds';
import type { CartLineItem } from 'root/services/cartService';
import { state as rootState } from '../../states/RootState';
import type { CartButtonNavigationTarget } from 'root/types/cartButton';

export class CartButtonController {
  private isMobile: boolean;
  private t: PlatformControllerFlowAPI['translations']['t'];
  private isEditor: boolean;
  private isMemberLoggedIn: boolean;

  constructor(private bind: Bind<I$W>, $w: I$W, flowAPI: PlatformControllerFlowAPI) {
    const {
      environment: { isMobile, isEditor },
      translations: { t },
      controllerConfig,
    } = flowAPI;

    this.isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser.loggedIn;

    this.isMobile = isMobile;
    this.isEditor = isEditor;
    this.t = t;

    rootState.CartService?.onChange(() => {
      const { label, isCollapsed } = this.getButtonProps();
      $w(CART_BUTTON_COMPONENT_IDS.button).label = label;
      $w(CART_BUTTON_COMPONENT_IDS.viewCartBox)[isCollapsed ? 'collapse' : 'expand']();
      $w(CART_BUTTON_COMPONENT_IDS.container)[isCollapsed ? 'collapse' : 'expand']();
    });
  }

  private getButtonProps(): { label: string; isCollapsed: boolean } {
    const count = this.getCartItemCount();
    return {
      label: this.t('navigation.cart-button.title', {
        count,
      }),
      isCollapsed: !this.isEditor && count === 0,
    };
  }

  private getCartItemCount() {
    return Array.from(rootState.cartLineItems.values())
      .flat()
      .reduce((acc: number, item: CartLineItem) => acc + (item?.quantity ?? 0), 0);
  }

  private onCartButtonClick(navigationTarget: CartButtonNavigationTarget) {
    rootState.biReporterService?.reportOloLiveSiteClickOnCartButton({
      isMemberLoggedIn: this.isMemberLoggedIn,
      cartState: this.getCartItemCount(),
      navigationTarget,
    });
    if (!this.isMobile && navigationTarget === 'mini-cart') {
      rootState.CartService?.openMiniCart();
    } else {
      rootState.CartService?.navigateToCartPage();
    }
  }

  async init(navigationTarget: CartButtonNavigationTarget) {
    this.bind(CART_BUTTON_COMPONENT_IDS.button, {
      label: () => {
        const { label } = this.getButtonProps();
        return label;
      },
      onClick: this.onCartButtonClick.bind(this, navigationTarget),
    });
    this.bind(CART_BUTTON_COMPONENT_IDS.viewCartBox, {
      collapsed: () => {
        const { isCollapsed } = this.getButtonProps();
        return isCollapsed;
      },
    });
    this.bind(CART_BUTTON_COMPONENT_IDS.container, {
      collapsed: () => {
        const { isCollapsed } = this.getButtonProps();
        return isCollapsed;
      },
    });
  }
}
